import classNames from "classnames";
import { useState } from "react";
import { COPILOT_FEATURE_ENABLE } from "../../../config/service";
import { getApiService, WEB_REQUEST_TYPE } from "../../../helpers/apiService";
import styles from "./CloudOpsCopilotFeatureStep.module.scss";

const CloudOpsCopilotFeatureStep = ({ parentNext }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isRequestingAccess, setIsRequestingAccess] = useState(false);
  const [isRequestingAccessSuccess, setIsRequestingAccessSuccess] =
    useState(false);

  const onRequestAccess = () => {
    setIsRequestingAccess(true);

    getApiService(WEB_REQUEST_TYPE.POST, COPILOT_FEATURE_ENABLE)
      .then((data) => {
        setIsRequestingAccessSuccess(true);
      })
      .finally(() => {
        setIsRequestingAccess(false);
        parentNext && parentNext();
      });
  };

  return (
    <div
      className={classNames(
        styles.welcomeDivLarge,
        styles.ContentBackground,
        styles.ConnectAccountContainer
      )}
    >
      <div className="d-flex w-100">
        <div className="w-50 d-flex flex-column px-4">
          <div>
            <img
              src={"/images/cloudops-copilot-64.png"}
              alt="co-pilot"
              style={{
                width: "77px",
                height: "77px",
              }}
            />
          </div>
          <div
            style={{
              fontSize: "36px",
              lineHeight: "44px",
              fontWeight: "500",
            }}
          >
            Try the Industry’s First
            <br /> Innovative <span style={{ color: "#315DCC" }}>
              CloudOps
            </span>{" "}
            <span
              style={{
                color: "#F99800",
              }}
            >
              Copilot
            </span>
          </div>
          <div className="d-flex flex-column gap-2 mt-4">
            <div className="d-flex gap-2">
              <img
                src="/images/fre/Copilot-Optimization.png"
                style={{
                  width: "80px",
                  height: "80px",
                }}
              />
              <div className="d-flex flex-column">
                <div
                  style={{
                    color: "#1E1F24",
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "26px",
                    letterSpacing: "0.4px",
                  }}
                >
                  Uncover Optimization Opportunities
                </div>
                <p
                  style={{
                    color: "#62636C",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "400",
                  }}
                >
                  Easily assess your cloud environment and identify cost-saving
                  opportunities, enhance security, boost performance, and
                  improve reliability—all based on industry best practices.
                </p>
              </div>
            </div>
            <div className="d-flex gap-2">
              <img
                src="/images/fre/Copilot-Reports.png"
                style={{
                  width: "80px",
                  height: "80px",
                }}
              />
              <div className="d-flex flex-column">
                <div
                  style={{
                    color: "#1E1F24",
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "26px",
                    letterSpacing: "0.4px",
                  }}
                >
                  Generate Executive-Friendly Reports
                </div>
                <p
                  style={{
                    color: "#62636C",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "400",
                  }}
                >
                  Translate complex technical assessments into
                  customer-friendly, business-centric reports, helping your
                  leadership understand the value of optimization
                  recommendations clearly and effectively.
                </p>
              </div>
            </div>
            <div className="d-flex gap-2">
              <img
                src="/images/fre/Copilot-MSP-Leads.png"
                style={{
                  width: "80px",
                  height: "80px",
                }}
              />
              <div className="d-flex flex-column">
                <div
                  style={{
                    color: "#1E1F24",
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "26px",
                    letterSpacing: "0.4px",
                  }}
                >
                  Turn Leads Into Customers (For MSPs)
                </div>
                <p
                  style={{
                    color: "#62636C",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "400",
                  }}
                >
                  Empower your sales teams with AI coaching, enabling them to
                  have insightful, value-driven conversations with
                  prospects—leading to more conversions and stronger customer
                  relationships.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-50 d-flex flex-column px-4">
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <iframe
              src={"/terms/terms-and-conditions.pdf"}
              style={{
                width: "100%",
                height: "100%",
                border: "none",
              }}
            />
          </div>
          <div
            onClick={() => setIsChecked(!isChecked)}
            className="d-flex gap-1 mt-4"
          >
            {isChecked ? (
              <img
                src={"/images/icons/checkbox-checked.svg"}
                alt="checked"
                style={{
                  width: "16px",
                }}
              />
            ) : (
              <img
                src={"/images/icons/checkbox-unchecked.svg"}
                alt="unchecked"
                style={{
                  width: "16px",
                }}
              />
            )}
            <div
              style={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "18px",
                color: "#1E1F24",
                cursor: "default",
              }}
            >
              I have read and agree to the MontyCloud’s terms and conditions.
            </div>
          </div>
          <div className="d-flex gap-1 mt-3 ">
            <button
              className={classNames(
                "w-50",
                styles.copilotButton,
                styles.tryLater
              )}
              disabled={isRequestingAccess || isRequestingAccessSuccess}
              onClick={() => {
                parentNext && parentNext();
              }}
            >
              Skip for Now
            </button>
            <button
              className={classNames("w-50", styles.copilotButton)}
              disabled={
                isRequestingAccess || isRequestingAccessSuccess || !isChecked
              }
              onClick={onRequestAccess}
              id="tryNow"
            >
              Enable CloudOps Copilot
            </button>
          </div>
        </div>
      </div>
      {/* <div className={styles.container}>
        <div className={styles.containerLabel}>
          <div className={styles.containerLabelTitle}>
            CloudOps Copilot(Beta)
          </div>
          <div className={styles.containerLabelSubtitle}>
            Transform Teams into Cloud and AI Powerhouses
          </div>
          <h5>
            Traditional Cloud Operations: Complex, Expensive and
            Undifferentiated
          </h5>
          <h5>
            <strong>AI-powered CloudOps:</strong> Conversational, Optimized and
            Autonomous
          </h5>
          <div className="d-flex gap-2">
            <button
              className="mt-4"
              disabled={isRequestingAccess || isRequestingAccessSuccess}
              onClick={onRequestAccess}
              id="tryNow"
            >
              Try it now
            </button>
            <button
              className={classNames("mt-4", styles.tryLater)}
              disabled={isRequestingAccess || isRequestingAccessSuccess}
              onClick={() => {
                parentNext && parentNext();
              }}
            >
              I'll try later
            </button>
          </div>
          <div className="mt-2 mc-text-sm-regular">
            By clicking try it now you are accepting the{" "}
            <a
              href="https://montycloud.com/platform-terms/"
              target="_blank"
              rel="noreferrer"
            >
              CloudOps Copilot terms and conditions
            </a>
          </div>
        </div>
        <div className={styles.containerTabs}>
          <Nav fill tabs className={styles.containerTabsNav}>
            <NavItem>
              <NavLink
                className={classNames(
                  activeTab === "1" ? "active" : "",
                  "mc-text-base-bold"
                )}
                onClick={() => {
                  setActiveTab("1");
                }}
              >
                Build a Cloud Center of Excellence
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames(
                  activeTab === "2" ? "active" : "",
                  "mc-text-base-bold"
                )}
                onClick={() => {
                  setActiveTab("2");
                }}
              >
                Implement Best Practices​
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames(
                  activeTab === "3" ? "active" : "",
                  "mc-text-base-bold"
                )}
                onClick={() => {
                  setActiveTab("3");
                }}
              >
                Upskill My Team​
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent
            activeTab={activeTab}
            className={styles.containerTabsNavContent}
          >
            <TabPane tabId="1">
              {activeTab === "1" && (
                <video width="600" controls muted autoPlay>
                  <source
                    src="/videos/CloudOps_Copilot_AI.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              )}
            </TabPane>
            <TabPane tabId="2">
              {activeTab === "2" && (
                <video width="600" controls muted autoPlay>
                  <source
                    src="/videos/CloudOps_Copilot_AI_Best_Practices.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              )}
            </TabPane>
            <TabPane tabId="3">
              {activeTab === "3" && (
                <video width="600" controls muted autoPlay>
                  <source
                    src="/videos/CloudOps_Copilot_AI_Upskill.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              )}
            </TabPane>
          </TabContent>
        </div>
      </div> */}
    </div>
  );
};

export { CloudOpsCopilotFeatureStep };
