import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../config/customBaseQuery";
import { ORG_TENANT_LIST } from "../../../config/service";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiServiceHelper";

export const tenantListApi = createApi({
  reducerPath: "tenantListAPI",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getTenantsList: builder.query({
      query: () => {
        return {
          url: ORG_TENANT_LIST,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
    }),
  }),
});

export const { useGetTenantsListQuery } = tenantListApi;
